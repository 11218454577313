<template>
    <div class="client">
        <div class="container">
            <h2 class="title">Struktur Organisasi</h2>

            <div class="list">
                <div v-for="list in clients" :key="list">
                    <!-- <router-link :to="'/organization/detail/'+list.id" class="card"> -->
                    <div class="card">
                        <div class="card-body d-flex justify-content-between align-items-center card-wrapper">
                            <div class="d-flex align-items-center">
                                <figure class="card-img-top">
                                    <img :src="imageUrl+'klien/'+list.image" alt="...">
                                </figure>
                                <p class="card-text w-100">
                                    {{ list.name }}
                                </p>
                            </div>
                            <button class="btn btn__primary">{{ list.position }}</button>
                        </div>
                    </div>
                    <!-- </router-link> -->
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue'

import axios from 'axios'
import { onMounted, ref } from 'vue'

export default {
    name: 'Client',
    components: {
        Footer,
    },
    setup(){
        const clients = ref([])

        onMounted(() => {
            axios.get(`getClient`)
			.then((result) => {
                console.log('Clients : ', result.data)
                clients.value = result.data
			}).catch((err) => {
				console.log(err.response)
			})
        })

        return{
            clients, 
        }
    },
}
</script>

<style lang="scss" scoped>
.client{
    .title{
        color: $blue;
        text-transform: uppercase;
        font-weight: 700;
        margin: 40px;
        text-align: center;
    }

    .list{
        .card{
			border: none;
            text-decoration: none;
			border-radius: 0;
			box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
            border: 1px solid $blue;
            margin-bottom: 20px;
            transition: all ease-in-out 0.3s;
			.card-img-top{
				width: 200px; 
				height: 200px; 
                margin-bottom: 0;
                flex-shrink: 0;
				img{
                    width: 100%;
					height: 100%;
					object-fit: cover;
                    transition: all ease-in-out 0.3s;
				} 
			}
			.card-body{
                .card-text{
                    font-weight: 700;
                    max-width: calc(100% - 300px);
                    margin-bottom: 0;
                    font-size: 11pt;
                    padding: 0 1rem;
                    color: $blue;
                    display: flex;
                    text-decoration: none;
                    transition: all ease-in-out 0.3s;
                }
                .btn__primary{
                    max-width: 300px;
                    @media (max-width: 767.98px) {
                        max-width: 100%;
                    }
                }
            }

            &:hover{
                background: $blue;
                .card-body{
                    .card-text{
                        color: white;
                    }
                }
            }
		}
    }

}

.card-wrapper {
    @media (max-width: 767.98px) {
        flex-direction: column!important;;
        > div {
            margin-bottom: 1rem;
            flex-direction: column!important;
            .card-text {
                margin-bottom: 1rem;
                max-width: 100%!important;
            }
            .card-img-top {
                margin-bottom: 1rem!important;
            }
        } 
    }
}
</style>
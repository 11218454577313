<template>
    <div class="berita">
        <div class="card">
            <figure class="card-img-top">
                <img :src="imageUrl+'berita/'+beritaLain.image" alt="...">
            </figure>
            <div class="card-body">
                <p class="card-text" v-if="beritaLain.title.length>=34">{{beritaLain.title.substring(0,34)+'...'}}</p>
                <p class="card-text" v-else>{{beritaLain.title}}</p>
                <small class="time">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-clock" viewBox="0 0 16 16">
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                    </svg>
                    {{dateTime(beritaLain.created_at)}}
                </small>
                <div class="card-footer">
                    <router-link :to="'/news/detail/'+beritaLain.id" class="btn btn__primary">
                        Lihat
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                            <g id="feather-arrow-right" transform="translate(-6 -6)">
                                <path id="Union_1" data-name="Union 1" d="M4.558,9.817a.626.626,0,0,1,0-.884L7.867,5.625H.625a.625.625,0,0,1,0-1.25H7.866L4.558,1.067A.625.625,0,0,1,5.442.183L9.817,4.558l0,0,0,0,.008.008v0a.624.624,0,0,1,.161.5v.014l0,.01v0l0,.012v0l0,.013h0c0,.01,0,.019-.007.029h0l0,.014h0l0,.013v0l0,.012v0l0,.01,0,.005,0,.008,0,.009,0,0-.005.012v0a.616.616,0,0,1-.078.128l-.005.006,0,.005L9.852,5.4l0,0-.008.009,0,0-.009.01h0l-.011.011L5.442,9.817a.626.626,0,0,1-.884,0Z" transform="translate(6 6)" fill="#0285b3"/>
                            </g>
                        </svg>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'Berita',
    props: ["beritaLain"],
    setup(){
        function dateTime(value) {
			return moment(value).format("DD MMMM YYYY hh:mm:ss A");
		}

        return{
            dateTime,
        }
    }
}
</script>

<style lang="scss" scoped>
.berita{
    .card{
		border: none;
		border-radius: 0;
		box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
        margin-bottom: 40px;
        height: 300px;
		.card-img-top{
			width: 100%;
			height: 180px;
			margin-bottom: 0;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.card-text{
			font-weight: 700;
			margin-bottom: 0;
            color: $darkGrey;
		}
		.time{
			font-size: 8pt;
			color: $lightGrey;
			svg{
				width: 12px;
				height: 12px;
			}
		}
        .card-footer{
            background: none;
            border: none;
            .btn__primary{
                font-size: 10pt;
                float: right;
                padding: 10px 20px 10px 40px;
                svg{
                    margin-left: 20px;
                }
            }
        }
	}
}
@media (max-width: 767.98px)
{
    .berita{
        .card{
            height: 240px;
            .card-img-top{
                height: 120px;
            }
            .time{
                font-size: 6pt;
                color: $lightGrey;
                display: flex;
                align-items: flex-start;
                svg{
                    margin-right: 3px;
                    margin-bottom: 10px;
                    width: 10px;
                    height: 10px;
                }
            }
            .card-text{
                font-size: 9pt;
            }
            .card-footer{
                .btn__primary{
                    font-size: 8pt;
                    float: right;
                    padding: 10px;
                    svg{
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
</style>
<template>
  <div class="training">
    <div class="head">
      <img src="../assets/img/icons/circle-two2.svg" alt="" class="circle" />
      <img src="../assets/img/icons/dot-vertical-22.svg" alt="" class="dot_v" />
      <img src="../assets/img/icons/rect-layer2.svg" alt="" class="rect" />
      <img
        src="../assets/img/icons/dot-vertical-22.svg"
        alt=""
        class="dot_v2"
      />
      <div class="container">
        <div class="inner">
          <h4 class="subtitle">Pelatihan</h4>
          <h1 class="title">
            Program Pelatihan
          </h1>
        </div>
      </div>
    </div>

    <div class="product">
      <div class="container">
        <div class="" v-for="item in products" :key="item">
          <ProductCart :item="item" />
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Footer from "@/components/Footer.vue";
import ProductCart from "@/components/ProductCart.vue";

import axios from "axios";
import { onMounted, ref } from "vue";

export default {
  name: "Training",
  components: {
    Footer,
    ProductCart,
  },
  setup() {
    const products = ref([]);

    onMounted(() => {
      axios
        .get("getType")
        .then((result) => {
          console.log("Produk : ", result.data);
          products.value = result.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    });

    return {
      products,
    };
  },
};
</script>

<style lang="scss" scoped>
.training {
  .head {
    background: $blue;
    position: relative;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    .circle {
      position: absolute;
      top: 0;
      left: 0;
      width: 400px;
      max-width: 100%;
      height: auto;
      z-index: 2;
      opacity: 0.06;
    }
    .dot_v {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 90px;
      height: auto;
      z-index: 2;
      opacity: 0.06;
    }
    .dot_v2 {
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 90px;
      height: auto;
      z-index: 2;
      opacity: 0.06;
    }
    .rect {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 420px;
      height: auto;
      z-index: 1;
      opacity: 0.06;
    }
    .inner {
      .subtitle {
        color: white;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14pt;
      }
      .title {
        color: white;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .product {
    padding: 40px 0;
  }
  @media (max-width: 767.98px) {
    .product {
      margin: 0 10%;
    }
  }
}
</style>
<template>
	<nav class="navbar sticky-top navbar-expand-lg navbar-light" id="nav">
		<div class="container">
			<router-link class="navbar-brand" to="/">
				<img src="./assets/img/logo.jpg" class="logo" />
				<div class="logo_name">
					<h4>IAI Bali</h4> 
				</div>
			</router-link>
			<button class="navbar-toggler" type="button" @click.prevent="showNavMenu()" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse justify-content-center" :class="showMenu ? 'show' : ''" id="navbarSupportedContent">
				<div class="navbar-nav ms-auto">
					<router-link to="/" class="nav-link" @click.prevent="showNavMenu()">Beranda</router-link>
					<router-link :to="{ name: 'training' }" class="nav-link" @click.prevent="showNavMenu()">Pelatihan</router-link>
					<router-link to="/news" class="nav-link" @click.prevent="showNavMenu()">Berita</router-link>
					<router-link to="/organization" class="nav-link" @click.prevent="showNavMenu()">Struktur Organisasi</router-link>
					<router-link to="/about" class="nav-link" @click.prevent="showNavMenu()">Tentang Kami</router-link>
					<router-link to="/contact" class="nav-link" @click.prevent="showNavMenu()">Hubungi Kami</router-link>
				</div>
			</div>
		</div>
	</nav>
	<router-view/>
</template>

<script>

export default {
	data(){
		return {
			showMenu: false
		}
	},
	methods: {
		showNavMenu(){
			this.showMenu ? this.showMenu = false : this.showMenu = true;
		}
	}
}
</script>

<style lang="scss">

.navbar {
	padding: 30px;
	background: white;
	box-shadow: 0 3px 20px rgba($color: #000000, $alpha: 0.05);

	.navbar-brand{
		display: flex;
		align-items: center;

		.logo{
			width: 90px;
			margin-right: 10px;
		}
		.logo_name{
			text-align: center;
			color: $blue;
			h4{
				font-weight: 600;
				font-size: 20pt;
				text-transform: uppercase;
				margin: 0;
				// border-bottom: 1px solid #d1d1d1;
			}
			.title{
				margin: 0;
				font-size: 8pt;
				letter-spacing: 2px;
				text-transform: uppercase;
			}
			.tagline{
				font-size: 6pt;
				margin: 0;
			}
		}
	}

	a.nav-link {
		font-weight: 600;
		color: $grey;
		font-size: 11pt;
		text-transform: capitalize;
		&:hover{
			color: $blue !important;
		}
		&.router-link-active {
			color: $blue;
		}
		&.router-link-exact-active {
			color: $blue;
		}
	}
}
.block-mobile{
	display: none;
}
@media (max-width: 767.98px){
	.block-mobile{
		display: block;
	}
	.navbar{
		.navbar-brand{
			margin: 0;
			.logo{
				width: 60px;
			}
			.logo_name{
				
				h4{
					font-weight: 600;
					font-size: 14pt;
					text-transform: uppercase;
					margin: 0;
					// border-bottom: 1px solid $blue;
				}
				.title{
					margin: 0;
					font-size: 7pt;
					letter-spacing: 0px;
					text-transform: uppercase;
				}
				.tagline{
					font-size: 4.2pt;
					margin: 0;
				}
			}
		}
	}
}
</style>
